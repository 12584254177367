import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Banner from './components/banner';
import WishesList from './components/wishes-list';
import WishPopup from './components/wish-popup';
import './App.css';
import Footer from './components/footer';
import { fetchWishDetails, createWishes } from './services/api';
import ThanksPopup from './components/thankyou-popup';
import Login from './pages/login'
// import { useLocation } from 'react-router-dom';

const App = () => {
  const [wishes, setWishes] = useState([]);
  const [activeWish, setActiveWishes] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isThanksOpen, setIsThanksOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [totalPage, setTotalPage] = useState(2);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState((localStorage.getItem('jswtoken'))? true: false);
  // const [loginOpen, setLoginOpen] = useState((localStorage.getItem('jswtoken'))? true: false);
console.log(isLoggedIn);
  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  const handleLoginClose = () => {
    setIsLoggedIn(false);
  };
 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      console.log(isMobile);
      if(window.innerWidth <= 768){
        setLimit(4);
      }
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const wish = queryParams.get('wish');
    if (wish) {
      setActiveWishes(wish);
      openThanksPopup();
    }
  }, [window.location.search]);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  
  const openThanksPopup = () => {
    setIsThanksOpen(true);
  };

  const closeThanksPopup = () => {
    setIsThanksOpen(false);
    setActiveWishes('')
  };

  const handleWishSubmit =async (wish) => {
    console.log(wish);
    const data = await createWishes(wish);
    console.log(data);
    if(data.status==="Success"){
      setActiveWishes(data.data[0]._id);
      openThanksPopup();
      setWishes([wish, ...wishes]);
    }
  };
  
  useEffect(() => {
    const loadWishes = async () => {
      let _limit = (isMobile && page>1)? 2: (isMobile && page===1)? 4: (page===1)? limit: 3;
      let skip = wishes.length;
      const data = await fetchWishDetails(page, _limit,skip);
      console.log(data);
      setTotalPage(data.totalPage);
      setTotalCount(data.totalCount);
      if(page===1){
        setWishes(data.data);
      }else{
        setWishes([...wishes, ...data.data]);
      }
    };

    loadWishes();
  }, [page, isMobile, activeWish, limit]);

  return (
    <Router>
      <div className="App">
        <Header/>
        {/* <Login isOpen={!isLoggedIn} onLogin={setIsLoggedIn} onClose={handleLoginClose} /> */}
        <Banner openPopup={openPopup} />
        <WishesList wishes={wishes} totalPage={totalPage} totalCount={totalCount} page={page} setPage={setPage} />
        <WishPopup isOpen={isPopupOpen} onClose={closePopup} onSubmit={handleWishSubmit} />
        <ThanksPopup isOpen={isThanksOpen} onClose={closeThanksPopup} wish={activeWish} />
        <Footer />
        <Routes>
          <Route path="/" element={
            <>
              {/* <button className="banner-button" onClick={() => setIsModalOpen(true)}>
                Create Record
              </button> */}

              {/* {isModalOpen && (
                <div className="modal">
                  <RecordForm addRecord={addRecord} closeModal={() => setIsModalOpen(false)} />
                </div>
              )}

              <div className="record-container">
                {records.map((record, index) => (
                  <RecordCard key={index} record={record} />
                ))}
              </div> */}
            </>
          } />
          {/* // <Route path="/login" element={<LoginPage />} /> */}
          {/* // <Route path="/message" element={<MessagePage />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;