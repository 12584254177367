// src/components/WishPopup.js
import React, { useState, useEffect, useRef } from 'react';
import './css/wishpopup.css';
import Card from './card';
import { fetchCardDetails } from '../services/api';

const WishPopup = ({ isOpen, onClose, onSubmit }) => {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [selectedCard, setSelectedCard] = useState(null);
    const [errmsg, setErr] = useState('');

    const popupRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const scrollContainerRef1 = useRef(null);

    const [cards, setCards] = useState([]);
    const [cards2, setCards2] = useState([]);

    const handleSelect = (item) => {
        setSelectedCard(item);
    };

    const splitArray = (arr) => {
        const midIndex = Math.ceil(arr.length / 2);
        const arra1 = arr.slice(0, midIndex);
        const arra2 = arr.slice(midIndex);
      
        return [arra1, arra2];
    };

    useEffect(() => {
        const loadCards = async () => {
          const data = await fetchCardDetails();
          const [arra1, arra2] = splitArray(data.data);
          setCards(arra1);
          setCards2(arra2);
        };
    
        loadCards();
      }, [isOpen]);
      console.log(selectedCard);
      
    const handleSubmit = (event) => {
        event.preventDefault();
        let err = ''
        if(!name || !location || !selectedCard ){
           err += (!name || !location)? "Name and location required." : "";
           err += (!selectedCard?._id)? " Please select message.": "";
           setErr(err);
        }else{
            onSubmit({name: name, location: location, message: selectedCard.message+ ' '+ selectedCard.hashtags});
            setName('');
            setLocation('');
            setSelectedCard(null);
            onClose();
        }
    };

    const scrollLeft = () => {
        
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollBy({
            left: -scrollContainerRef.current.clientWidth - 8,
            behavior: 'smooth',
          });
          
        }
        console.log(scrollContainerRef.current.clientWidth);
        if (scrollContainerRef1.current) {
            scrollContainerRef1.current.scrollBy({
              left:  -scrollContainerRef1.current.clientWidth -8,
              behavior: 'smooth',
            });
          }
      };
    
      const scrollRight = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollBy({
            left:  scrollContainerRef.current.clientWidth +8,
            behavior: 'smooth',
          });
        }
        if (scrollContainerRef1.current) {
            scrollContainerRef1.current.scrollBy({
              left: scrollContainerRef1.current.clientWidth +8,
              behavior: 'smooth',
            });
          }
      };

    // const scrollLeft = () => {
    //     const scrollDistance = window.innerWidth < 430 ? -330: -444; 
    //     if (scrollContainerRef.current) {
    //       scrollContainerRef.current.scrollBy({
    //         left: scrollDistance,
    //         behavior: 'smooth',
    //       });
    //     }
    //     if (scrollContainerRef1.current) {
    //       scrollContainerRef1.current.scrollBy({
    //         left: scrollDistance,
    //         behavior: 'smooth',
    //       });
    //     }
    //   };
      
    //   const scrollRight = () => {
    //     const scrollDistance = window.innerWidth < 430 ? 328 : 444; 
    //     if (scrollContainerRef.current) {
    //       scrollContainerRef.current.scrollBy({
    //         left: scrollDistance,
    //         behavior: 'smooth',
    //       });
    //     }
    //     if (scrollContainerRef1.current) {
    //       scrollContainerRef1.current.scrollBy({
    //         left: scrollDistance,
    //         behavior: 'smooth',
    //       });
    //     }
    //   };
      

    const handleOutsideClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            onClose();
        }
    };


    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <div className={ (isOpen)? "popup open": "popup"}>
            <div className="popup-inner" ref={popupRef}>
                <div className="popup-content">
                    <div className="popup-image">
                        <img src="images/new-wish-img.png" className='desktop-img' alt="Popup" />
                        {/* <img src="images/popup-mobile-img.png" className='mobile-img' alt="Popup" /> */}

                    </div>
                    <div className="popup-form">
                        <h2>Wish Team India</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name
                                <input type="text" value={name} onChange={(e) => { setName(e.target.value) }}  />
                            </label>
                            <label>
                                Your Location
                                <input type="text" value={location} onChange={(e) => { setLocation(e.target.value) }}  />
                            </label>
                            <div className="card-slider-container">
                                <div className='d-flex justify-content-between align-items-center pb-2'>
                                    <label className='m-0'>Select Your Message </label>
                                    <div className='scroll-btns'>
                                    <button type='button' onClick={scrollLeft} className="scroll-button">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.905859" y="0.4" width="31.2" height="31.2" rx="15.6" stroke="#D5D9E2" stroke-width="0.8"/>
                                        <path d="M18.9055 22.3366L13.6895 17.1206C13.0735 16.5046 13.0735 15.4966 13.6895 14.8806L18.9055 9.66455" stroke="#2D57A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <button type='button' onClick={scrollRight} className="scroll-button">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.905859" y="0.4" width="31.2" height="31.2" rx="15.6" stroke="#D5D9E2" stroke-width="0.8"/>
                                        <path d="M14.0332 22.3366L19.2492 17.1206C19.8652 16.5046 19.8652 15.4966 19.2492 14.8806L14.0332 9.66455" stroke="#2D57A7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    </div>
                                </div>
                                
                                <div className="card-slider" ref={scrollContainerRef}>
                                    {cards.map((item, index) => (
                                    <Card
                                        key={index}
                                        item={item}
                                        onClick={handleSelect}
                                        isSelected={item?._id === selectedCard?._id}
                                    />
                                    ))}
                                </div>
                                <div className="card-slider" ref={scrollContainerRef1}>
                                    {cards2.map((item, index) => (
                                    <Card
                                        key={index}
                                        item={item}
                                        onClick={handleSelect}
                                        isSelected={item?._id === selectedCard?._id}
                                    />
                                    ))}
                                </div>
                            </div>
                            <div className='terms-conditions'>
                            By Clickling on Submit I agree to <a href='https://jsw.in'>Terms & Conditions</a>
                            </div>
                            {(errmsg)? 
                            <div className='terms-conditions text-danger p-0'>{errmsg}
                            </div>
                            :""}
                            <div className="popup-btn-container">
                            <button type="button" className='back-btn' onClick={onClose}>Go Back</button>
                            <button type="submit" className='submit-btn'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WishPopup;
