import React, { useEffect, useRef, useState } from 'react';
import './css/wishpopup.css';
import './css/thankyou.css';
import { getWishById, uploadImage } from '../services/api';
// import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from 'html2canvas';

const ThanksPopup = ({ isOpen, onClose, wish }) => {
    console.log(wish);
    const [selectedWish, setSelectedWish] = useState(null);
    const [message, setMessage] = useState('');
    const [tags, setTag] = useState('');
    const [img, setImage] = useState('')
    const [imgDataURI, setDataURI] = useState('')

    const captureRef = useRef(null);

    const download = () => {
        // Create a link element to download the image
        const link = document.createElement('a');
        link.href = imgDataURI;
        link.download = `${wish}.png`;
        link.click();
    }
    const handleCapture = () => {
        if (captureRef.current) {

            // Define device-specific viewport sizes
            let viewportWidth, viewportHeight;
            viewportWidth = 1400; // Example desktop width
            viewportHeight = 900; // Example desktop height

            // Temporarily set viewport size
            // const originalWidth = window.innerWidth;
            // const originalHeight = window.innerHeight;
            if (viewportWidth < 768) {
                captureRef.current.style.width = `${viewportWidth}px`;
                captureRef.current.style.height = `${viewportHeight}px`;
            }
            // window.scrollTo(0, 0);

            html2canvas(captureRef.current, {
                scale: 2, // Higher resolution
                useCORS: true, // Handle CORS issues
            }).then(async (canvas) => {
                const img = canvas.toDataURL('image/png');
                setDataURI(img);
                let data = {
                    dataUri: img,
                    name: wish
                }
                let imgData = await uploadImage(data)
                console.log(imgData);
                setImage(imgData.fileUrl)

                // Restore original viewport size
                // captureRef.current?.style.width = `100%`;
                // captureRef.current?.style.height = `100%`;
            });
        }
    };

    useEffect(() => {
        const getWish = async () => {
            if (wish) {
                const data = await getWishById(wish.trim());
                setSelectedWish(data.data);
                const hashIndex = data.data?.message.indexOf('#');
                const beforeHash = hashIndex !== -1 ? data.data?.message.substring(0, hashIndex).trim() : data.data?.message;
                const afterHash = hashIndex !== -1 ? data.data?.message.substring(hashIndex).trim() : '';

                setMessage(beforeHash);
                setTag(afterHash)
                if (data.status === "Success") {
                    setTimeout(() => {
                        handleCapture()
                    }, 2000);
                }
            }
        };

        getWish();
    }, [isOpen, wish]);
    // console.log(process.env);

    // const imagesDir = [process.env.REACT_APP_URL, 'images'].join('/');
    // console.log(imagesDir);
    return (
        <div className={`modal fade thank-you-model ${(isOpen) ? 'show d-block' : ''}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!isOpen}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card" >
                                    <div className="mobile-close mobile-thanks-close">
                                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                                    </div>
                                    {/* {(img) ? (
                                        <div className="image-container">
                                            <img src={imgDataURI} className="card-img-top" alt="Team India" />
                                        </div>
                                    ) : ( */}
                                        <div ref={captureRef}>
                                        <div className="image-container" >
                                           
                                            <img src="./images/thank-new-img.jpeg" className="card-img-top" alt="Team India" />
                                            <div className="card-img-overlay">
                                                <img src="./images/border-img.png" className="card-img-top" alt="Team India" />
                                                <div className="text-overlay text-white">
                                                    <h3 className="text-uppercase PPMonumentExtended-Black">{message}</h3>
                                                    <p className="text-uppercase text-white MonumentExtended-Regular">{tags}</p>
                                                    <p className="name Rajdhani-Bold text-white mb-1">{selectedWish?.name}</p>
                                                    <p className="text-center Rajdhani-Medium location text-white mb-1">{selectedWish?.location}</p>
                                                    <p>
                                                        <a href="https://www.jsw.in/paris2024" target="_blank" rel="noopener noreferrer" className="Rajdhani-Medium email text-white">
                                                            www.jsw.in/paris2024
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    {/* )} */}
                                </div>
                            </div>
                            <div className="col-md-6 thank-you-col">
                                <div className="card w-100 h-100 thank-you-card" style={{ backgroundColor: "#DCFFD9" }}>
                                    <div className="desktop-close">
                                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                                    </div>
                                    <div className="card-body">
                                        <div className="row h-100 w-100 ms-0">
                                            <div className="col-12 text-center d-flex flex-column align-items-center justify-content-end">
                                                <h2 className="card-title thank-you-text">Thank You! 🎉</h2>
                                                <p>Thanks for adding a wish for Team India!</p>
                                            </div>
                                            <div className="col-12 d-flex flex-column align-items-center justify-content-end">
                                                <div className="social-buttons text-center">
                                                    <span>Share and spread the cheer</span>
                                                    <ul className="list-inline social-icons mt-3">
                                                        {/* <li className="list-inline-item">
                                                            <a href={'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURI(img)} className="text-light" target="_blank" rel="noopener noreferrer"><img src="images/Frame-32.svg" alt="" /></a>
                                                        </li> */}
                                                        {/* <li className="list-inline-item">
                                                            <a href={'https:/youtube.com/paris2024'} className="text-light" target="_blank" rel="noopener noreferrer"><img src="images/youtube-footer.png" alt="" /></a>
                                                        </li> */}
                                                        {/* <li className="list-inline-item">
                                                            <a href={'https:/youtube.com/paris2024'} className="text-light" target="_blank" rel="noopener noreferrer"><img src="images/youtube-footer.png" alt="" /></a>
                                                        </li> */}

                                                        {/* <li className="list-inline-item">
                                                            <a href={`https://www.instagram.com/create/story/?text=${encodeURIComponent('Join me in cheering for Team India going to Paris Olympics 2024! Post your wish at https://wishteamindia.in/ #RuknaNahiHai')}`} className="text-light" target="_blank" rel="noopener noreferrer"><img
                                                                src="images/instagram.png" alt="" className="icon-size"/></a>
                                                        </li> */}

                                                        <li className="list-inline-item">
                                                            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent('https://wishteamindia.in')}&text=${encodeURIComponent('Join me in cheering for Team India going to Paris Olympics 2024! Post your wish at https://wishteamindia.in/ #RuknaNahiHai')}&summary=${encodeURIComponent('#RuknaNahiHai')}`} className="text-light" target="_blank" rel="noopener noreferrer">
                                                            <img src="images/Frame-32.svg" alt="" />
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a href={`https://wa.me/?text=${encodeURIComponent('Join me in cheering for Team India going to Paris Olympics 2024! Post your wish at https://wishteamindia.in/ #RuknaNahiHai')}`} className="text-light" target="_blank" rel="noopener noreferrer"><img
                                                                src="images/whatsapp2.png" alt="" className="icon-size"/></a>
                                                        </li>


                                                        <li className="list-inline-item">
                                                            <a href={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent('https://wishteamindia.in')+ '&quot='+ encodeURIComponent('Join me in cheering for Team India going to Paris Olympics 2024! Post your wish at https://wishteamindia.in/ #RuknaNahiHai')} className="text-light" target="_blank" rel="noopener noreferrer"><img src="images/facebook-svg.svg" alt="" /></a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a href={'https://twitter.com/intent/tweet?text=' + encodeURIComponent('Join me in cheering for Team India going to Paris Olympics 2024! Post your wish at https://wishteamindia.in/ #RuknaNahiHai') } className="text-light" target="_blank" rel="noopener noreferrer"><img src="images/xtwi-svg.svg" alt="" /></a>
                                                        </li>
                                                        <li className="list-inline-item mt-3">
                                                            <button className="down-btn" onClick={download} disabled={!imgDataURI}>Download Card
                                                                <img src="images/Download.png" alt="" className="px-2" />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThanksPopup;
