import React from "react";
import navigationHook from "./hooks";
// import { NavLink } from "react-router-dom";

class Header extends React.Component {
    logoutHandler = () => {
        localStorage.removeItem("token");
        window.location.reload(false);
    };

    render() {
        return (
            <>
                <div id="i6po" class="gjs-grid-row" style={{ color: "#FFFFFFE5;" }}>
                    <div id="itjq" class="gjs-grid-column">
                        <div id="iyd2" class="gjs-grid-row">
                            <div id="iqjl" class="gjs-grid-column">
                                <a id="iucmr" href="/" class="gjs-link-box">
                                </a>
                                <div data-type-icon="" id="irfl3k" class="gjs-icon">
                                    <a id="iucmr" href="/" class="gjs-link-box">
                                    </a>
                                    <a class="navbar-brand" href="/">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="desktop-img" width="100" height="66" viewBox="0 0 100 66" fill="none">
                                            <path d="M51.2035 50.6296C51.4235 50.6296 51.6436 50.6398 51.8568 50.6604C52.3244 50.7049 52.7749 50.8075 53.2081 50.9444C55.5016 51.6871 57.2312 53.6754 57.6026 56.1053C58.0358 56.2456 58.4863 56.3449 58.9539 56.3893C58.6616 53.0868 56.289 50.3832 53.1462 49.5755C52.7267 49.466 52.2969 49.3975 51.8499 49.3599C51.6367 49.3428 51.4201 49.3325 51.2035 49.3325C50.9662 49.3325 50.7324 49.3462 50.502 49.3667C50.5226 49.5721 50.5295 49.7774 50.5295 49.993C50.5295 50.2189 50.5192 50.4448 50.4951 50.6672C50.7289 50.6398 50.9628 50.6296 51.2035 50.6296Z" fill="#00A44F" />
                                            <path d="M59.607 42.228C55.5185 42.228 52.1625 45.3697 51.8496 49.3636C52.2966 49.3978 52.7264 49.4697 53.1459 49.5792C53.3523 46.2013 56.1615 43.5251 59.607 43.5251C63.0524 43.5251 66.0886 46.4101 66.0886 49.9762C66.0886 53.5422 63.1865 56.4272 59.607 56.4272C59.3869 56.4272 59.1668 56.4135 58.9536 56.393C58.486 56.3485 58.0355 56.2493 57.6023 56.109C55.3053 55.3663 53.5758 53.3814 53.2078 50.9481C52.7746 50.8112 52.3241 50.7085 51.8565 50.664C52.1488 53.9666 54.5214 56.6771 57.6642 57.4813C58.0837 57.584 58.5169 57.6593 58.9571 57.6969C59.1703 57.714 59.3869 57.7243 59.607 57.7243C63.9086 57.7243 67.3918 54.2575 67.3918 49.9796C67.3918 45.7017 63.9086 42.2349 59.607 42.2349" fill="#EC344F" />
                                            <path d="M33.6516 49.3875C33.349 45.38 29.9964 42.228 25.8908 42.228C21.7852 42.228 18.1094 45.6948 18.1094 49.9727C18.1094 54.2506 21.5961 57.7174 25.8908 57.7174C26.128 57.7174 26.355 57.7072 26.5785 57.6866C26.5647 57.4916 26.5544 57.2965 26.5544 57.098C26.5544 56.8584 26.5682 56.6189 26.5854 56.3793C26.3584 56.4067 26.1246 56.4204 25.8873 56.4204C22.3113 56.4204 19.4057 53.5319 19.4057 49.9693C19.4057 46.4067 22.3113 43.5182 25.8873 43.5182C29.4634 43.5182 32.1627 46.2116 32.3552 49.6065C32.3621 49.7263 32.3724 49.8461 32.3724 49.9693C32.3724 50.315 32.338 50.6504 32.283 50.9823C31.9013 53.3779 30.1993 55.3355 27.9402 56.085C27.8851 56.417 27.8507 56.7523 27.8507 57.098C27.8507 57.2212 27.8611 57.341 27.8714 57.4608C30.9833 56.6497 33.3352 53.9632 33.6344 50.6846C33.6584 50.4484 33.6722 50.2123 33.6722 49.9693C33.6722 49.7708 33.6653 49.5757 33.6447 49.3841" fill="#0E80C3" />
                                            <path d="M34.3416 50.6512C34.5651 50.6512 34.7817 50.6614 34.9983 50.6854C35.4625 50.7333 35.9164 50.8326 36.3462 50.9729C38.6432 51.7155 40.3693 53.7005 40.7407 56.1303C41.174 56.2706 41.6244 56.3665 42.092 56.4144C41.7963 53.1118 39.4272 50.4082 36.2844 49.604C35.8648 49.4944 35.4316 49.426 34.9915 49.3883C34.7783 49.3712 34.5616 49.3575 34.3416 49.3575C34.1078 49.3575 33.8843 49.3712 33.6539 49.3918C33.6711 49.5868 33.6814 49.7785 33.6814 49.977C33.6814 50.2165 33.6676 50.4561 33.6436 50.6922C33.8739 50.6683 34.1078 50.6546 34.3416 50.6546" fill="#FBAF30" />
                                            <path d="M40.803 57.4973C40.6001 60.8752 37.7908 63.5548 34.342 63.5548C30.8931 63.5548 28.0701 60.8581 27.8809 57.4631C27.8741 57.3433 27.8603 57.227 27.8603 57.1003C27.8603 56.7581 27.8947 56.4193 27.9497 56.0873C28.328 53.6917 30.03 51.7376 32.2891 50.9847C32.3407 50.6527 32.3786 50.3173 32.3786 49.9717C32.3786 49.8485 32.3682 49.7287 32.3614 49.6089C29.2495 50.4234 26.8975 53.1065 26.5915 56.3782C26.5709 56.6178 26.5605 56.8574 26.5605 57.0969C26.5605 57.2954 26.5709 57.4905 26.5846 57.6856C26.8872 61.6863 30.2432 64.8485 34.3454 64.8485C38.4476 64.8485 41.7898 61.6999 42.1027 57.7095C41.6592 57.6719 41.2259 57.6 40.8064 57.4939" fill="#FBAF30" />
                                            <path d="M42.7456 56.4443C42.5255 56.4443 42.3089 56.4341 42.0923 56.4101C41.6246 56.3656 41.1742 56.2664 40.7409 56.1261C38.444 55.3834 36.7178 53.3985 36.3465 50.9686C35.9166 50.8283 35.4628 50.7291 34.9951 50.6812C35.2874 53.9837 37.66 56.6907 40.7994 57.495C41.2189 57.6011 41.6521 57.673 42.0957 57.7106C42.3123 57.7277 42.5255 57.738 42.7456 57.738C42.9828 57.738 43.2167 57.7243 43.447 57.7072C43.4299 57.4984 43.4195 57.2931 43.4195 57.0809C43.4195 56.8516 43.4299 56.6291 43.4505 56.4033C43.2201 56.4272 42.9828 56.4443 42.7456 56.4443Z" fill="#221F1F" />
                                            <path d="M50.502 49.3679C50.1788 45.3844 46.8331 42.2495 42.7446 42.2495C38.6562 42.2495 35.3037 45.3912 34.9873 49.3885C35.4309 49.4227 35.8607 49.4946 36.2802 49.6041C36.4865 46.2228 39.2958 43.5466 42.7412 43.5466C46.1866 43.5466 48.9959 46.2228 49.2022 49.5972C49.2125 49.7307 49.2228 49.8607 49.2228 50.0011C49.2228 50.3296 49.1885 50.6547 49.1403 50.973C48.7758 53.3857 47.0669 55.357 44.7974 56.1167C44.7459 56.435 44.7149 56.7533 44.7149 57.0887C44.7149 57.2256 44.7252 57.3556 44.7321 57.4891C47.8543 56.6677 50.2063 53.9641 50.4882 50.6753C50.5123 50.4528 50.5226 50.2269 50.5226 50.0011C50.5226 49.7889 50.5123 49.5801 50.4951 49.3748" fill="#221F1F" />
                                            <path d="M57.6648 57.4777C57.4585 60.8521 54.6492 63.5318 51.2038 63.5318C47.7584 63.5318 44.9491 60.8521 44.7393 57.4811C44.7325 57.3476 44.7187 57.2142 44.7187 57.0807C44.7187 56.7453 44.7497 56.427 44.8012 56.1087C45.1657 53.6926 46.8747 51.7213 49.1441 50.965C49.1922 50.6467 49.2266 50.325 49.2266 49.9931C49.2266 49.8562 49.2163 49.7227 49.206 49.5892C46.0873 50.4106 43.7387 53.1108 43.4499 56.4031C43.4293 56.6289 43.4189 56.8514 43.4189 57.0807C43.4189 57.2894 43.4293 57.4982 43.4465 57.707C43.7662 61.6905 47.1154 64.8254 51.2038 64.8254C55.2922 64.8254 58.6448 61.6803 58.9577 57.6899C58.5141 57.6522 58.0843 57.5803 57.6648 57.4743" fill="#00A44F" />
                                            <path d="M2.81956 25.4286H-3.8147e-05V23.3958H8.12179L7.21745 25.4286H5.30219V34.7031H2.81956V25.4286Z" fill="black" />
                                            <path d="M9.24911 34.7031V23.3958H16.1743L15.27 25.4286H11.7145V27.944H14.4757V29.9769H11.7145V32.6737H16.1743L15.27 34.7031H9.24911Z" fill="black" />
                                            <path d="M33.7871 29.4943H33.8525L35.9775 23.3958H39.6911V34.7031H37.322V26.3321H37.2566L34.7293 33.3479H32.6868L30.1904 26.3321H30.0942V34.7031H27.8213V23.3958H31.6312L33.7871 29.4943Z" fill="black" />
                                            <path d="M46.9789 23.3958H44.4894V34.7065H46.9789V23.3958Z" fill="#F68D39" />
                                            <path d="M49.4563 23.3958H46.9668V34.7065H49.4563V23.3958Z" fill="black" />
                                            <path d="M61.0889 23.3958H64.6202C68.3132 23.3958 69.9809 25.1856 69.9809 28.7962C69.9809 31.6812 68.8325 34.6928 64.6546 34.6928H61.0923V23.3958H61.0889ZM63.5509 32.66H64.4586C66.7762 32.66 67.4364 31.1952 67.4364 29.0186C67.4364 26.2945 66.2398 25.4252 64.4414 25.4252H63.5509V32.66Z" fill="black" />
                                            <path d="M73.7484 23.3958H71.2726V34.6928H73.7484V23.3958Z" fill="black" />
                                            <path d="M82.0312 23.3522L85.5007 34.7177H82.8943L81.7595 30.6657H78.7955L77.6127 34.7177H75.1025L78.5548 23.3522H82.0243H82.0312ZM78.854 28.6225H81.6736L81.2335 28.7389C80.7933 27.1646 80.501 26.0763 80.305 25.0702H80.2397C80.0437 26.0455 79.7308 27.2297 79.3251 28.6739L78.854 28.626V28.6225Z" fill="black" />
                                            <path d="M23.7542 23.3513L27.2236 34.7168H24.6172L23.4825 30.6648H20.5185L19.3391 34.7168H16.829L20.2847 23.3513H23.7542ZM20.577 28.6216H23.3966L22.9564 28.738C22.5163 27.1637 22.224 26.0754 22.028 25.0693H21.9627C21.7667 26.0446 21.4572 27.2287 21.048 28.673L20.577 28.625V28.6216Z" fill="black" />
                                            <path d="M56.4144 23.3958V26.9755C56.4144 28.1665 56.4144 29.7305 56.5451 31.3116H56.4797C56.0602 30.1822 55.4447 28.9262 54.8774 27.8448L52.5598 23.3958H51.9512V26.7017C52.3913 27.8516 52.8383 28.8954 53.3713 29.9255L55.8642 34.6963H58.7629V23.3992H56.4144V23.3958Z" fill="black" />
                                            <path d="M51.9477 23.3958H49.4513V34.7065H51.9477V23.3958Z" fill="#50A446" />
                                            <path d="M79.9583 2.82104C73.1775 10.1688 62.8722 9.33371 48.1243 6.56848C40.8828 5.21324 31.2377 3.5363 22.0086 3.9812C21.9467 3.99147 21.8814 4.00516 21.823 4.01543C21.823 4.01543 21.5135 4.01543 20.9736 4.03596C15.8055 4.37819 10.799 5.422 6.46301 7.5986C4.37238 9.30975 1.59404 12.1297 0.00199509 15.9901C0.00199509 15.9901 2.56714 14.498 5.41425 13.4028C5.63776 13.2865 5.85782 13.1701 6.0882 13.0503C19.2097 5.12768 32.1317 7.62597 44.0393 9.42269C55.6994 11.1818 69.8559 14.8573 78.8064 8.59107C79.0058 8.43706 79.2018 8.27279 79.3978 8.10168C79.5388 7.89634 79.6488 7.73207 79.6695 7.691C80.6494 6.00037 81.8873 3.11878 82.0008 0.545197C81.5641 1.05854 80.4844 2.24609 80.0993 2.66361C80.0855 2.6773 80.0786 2.68757 80.0649 2.70126C80.0064 2.76286 79.9686 2.80393 79.9583 2.81419V2.82104Z" fill="white" />
                                            <path d="M79.8508 2.89243C79.8302 2.9027 79.8096 2.91639 79.7855 2.93007C70.3295 9.2819 56.0802 3.46053 44.035 1.38319C31.7801 -0.731807 19.8828 -0.653094 8.81067 5.90065C8.39805 6.15732 7.7069 6.61591 6.87134 7.26957C6.73724 7.37567 6.5997 7.4886 6.45528 7.60154C10.7947 5.42152 15.7978 4.37772 20.9659 4.03891C21.3098 4.01495 21.6536 3.99784 22.0009 3.98073C31.2299 3.53582 40.875 5.21276 48.1166 6.568C62.8679 9.33323 73.1698 10.1683 79.9506 2.82056C79.9265 2.84109 79.8887 2.86505 79.8474 2.89243H79.8508Z" fill="#F68D39" />
                                            <path d="M78.8057 8.59763C69.8552 14.8639 55.6987 11.1883 44.0387 9.42925C32.131 7.63254 19.209 5.13424 6.08752 13.0569C5.94998 13.139 5.81244 13.2246 5.6749 13.3102C8.19534 12.369 11.1834 11.9275 13.8449 11.5477C16.6335 11.1541 19.4497 11.0549 22.2624 11.1712C27.8638 11.4039 33.4411 12.3006 38.9634 13.2041C41.7933 13.6695 44.6094 14.2376 47.4428 14.6825C63.6864 17.2424 71.8736 15.9762 78.4962 9.29921C78.5409 9.25472 78.5856 9.21023 78.6303 9.16574C78.8023 8.9604 79.0877 8.55656 79.318 8.2246C79.1496 8.35122 78.9811 8.47443 78.8091 8.59421L78.8057 8.59763Z" fill="#50A446" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mobile-img" width="50" height="33" viewBox="0 0 100 66" fill="none">
                                            <path d="M51.2035 50.6296C51.4235 50.6296 51.6436 50.6398 51.8568 50.6604C52.3244 50.7049 52.7749 50.8075 53.2081 50.9444C55.5016 51.6871 57.2312 53.6754 57.6026 56.1053C58.0358 56.2456 58.4863 56.3449 58.9539 56.3893C58.6616 53.0868 56.289 50.3832 53.1462 49.5755C52.7267 49.466 52.2969 49.3975 51.8499 49.3599C51.6367 49.3428 51.4201 49.3325 51.2035 49.3325C50.9662 49.3325 50.7324 49.3462 50.502 49.3667C50.5226 49.5721 50.5295 49.7774 50.5295 49.993C50.5295 50.2189 50.5192 50.4448 50.4951 50.6672C50.7289 50.6398 50.9628 50.6296 51.2035 50.6296Z" fill="#00A44F" />
                                            <path d="M59.607 42.228C55.5185 42.228 52.1625 45.3697 51.8496 49.3636C52.2966 49.3978 52.7264 49.4697 53.1459 49.5792C53.3523 46.2013 56.1615 43.5251 59.607 43.5251C63.0524 43.5251 66.0886 46.4101 66.0886 49.9762C66.0886 53.5422 63.1865 56.4272 59.607 56.4272C59.3869 56.4272 59.1668 56.4135 58.9536 56.393C58.486 56.3485 58.0355 56.2493 57.6023 56.109C55.3053 55.3663 53.5758 53.3814 53.2078 50.9481C52.7746 50.8112 52.3241 50.7085 51.8565 50.664C52.1488 53.9666 54.5214 56.6771 57.6642 57.4813C58.0837 57.584 58.5169 57.6593 58.9571 57.6969C59.1703 57.714 59.3869 57.7243 59.607 57.7243C63.9086 57.7243 67.3918 54.2575 67.3918 49.9796C67.3918 45.7017 63.9086 42.2349 59.607 42.2349" fill="#EC344F" />
                                            <path d="M33.6516 49.3875C33.349 45.38 29.9964 42.228 25.8908 42.228C21.7852 42.228 18.1094 45.6948 18.1094 49.9727C18.1094 54.2506 21.5961 57.7174 25.8908 57.7174C26.128 57.7174 26.355 57.7072 26.5785 57.6866C26.5647 57.4916 26.5544 57.2965 26.5544 57.098C26.5544 56.8584 26.5682 56.6189 26.5854 56.3793C26.3584 56.4067 26.1246 56.4204 25.8873 56.4204C22.3113 56.4204 19.4057 53.5319 19.4057 49.9693C19.4057 46.4067 22.3113 43.5182 25.8873 43.5182C29.4634 43.5182 32.1627 46.2116 32.3552 49.6065C32.3621 49.7263 32.3724 49.8461 32.3724 49.9693C32.3724 50.315 32.338 50.6504 32.283 50.9823C31.9013 53.3779 30.1993 55.3355 27.9402 56.085C27.8851 56.417 27.8507 56.7523 27.8507 57.098C27.8507 57.2212 27.8611 57.341 27.8714 57.4608C30.9833 56.6497 33.3352 53.9632 33.6344 50.6846C33.6584 50.4484 33.6722 50.2123 33.6722 49.9693C33.6722 49.7708 33.6653 49.5757 33.6447 49.3841" fill="#0E80C3" />
                                            <path d="M34.3416 50.6512C34.5651 50.6512 34.7817 50.6614 34.9983 50.6854C35.4625 50.7333 35.9164 50.8326 36.3462 50.9729C38.6432 51.7155 40.3693 53.7005 40.7407 56.1303C41.174 56.2706 41.6244 56.3665 42.092 56.4144C41.7963 53.1118 39.4272 50.4082 36.2844 49.604C35.8648 49.4944 35.4316 49.426 34.9915 49.3883C34.7783 49.3712 34.5616 49.3575 34.3416 49.3575C34.1078 49.3575 33.8843 49.3712 33.6539 49.3918C33.6711 49.5868 33.6814 49.7785 33.6814 49.977C33.6814 50.2165 33.6676 50.4561 33.6436 50.6922C33.8739 50.6683 34.1078 50.6546 34.3416 50.6546" fill="#FBAF30" />
                                            <path d="M40.803 57.4973C40.6001 60.8752 37.7908 63.5548 34.342 63.5548C30.8931 63.5548 28.0701 60.8581 27.8809 57.4631C27.8741 57.3433 27.8603 57.227 27.8603 57.1003C27.8603 56.7581 27.8947 56.4193 27.9497 56.0873C28.328 53.6917 30.03 51.7376 32.2891 50.9847C32.3407 50.6527 32.3786 50.3173 32.3786 49.9717C32.3786 49.8485 32.3682 49.7287 32.3614 49.6089C29.2495 50.4234 26.8975 53.1065 26.5915 56.3782C26.5709 56.6178 26.5605 56.8574 26.5605 57.0969C26.5605 57.2954 26.5709 57.4905 26.5846 57.6856C26.8872 61.6863 30.2432 64.8485 34.3454 64.8485C38.4476 64.8485 41.7898 61.6999 42.1027 57.7095C41.6592 57.6719 41.2259 57.6 40.8064 57.4939" fill="#FBAF30" />
                                            <path d="M42.7456 56.4443C42.5255 56.4443 42.3089 56.4341 42.0923 56.4101C41.6246 56.3656 41.1742 56.2664 40.7409 56.1261C38.444 55.3834 36.7178 53.3985 36.3465 50.9686C35.9166 50.8283 35.4628 50.7291 34.9951 50.6812C35.2874 53.9837 37.66 56.6907 40.7994 57.495C41.2189 57.6011 41.6521 57.673 42.0957 57.7106C42.3123 57.7277 42.5255 57.738 42.7456 57.738C42.9828 57.738 43.2167 57.7243 43.447 57.7072C43.4299 57.4984 43.4195 57.2931 43.4195 57.0809C43.4195 56.8516 43.4299 56.6291 43.4505 56.4033C43.2201 56.4272 42.9828 56.4443 42.7456 56.4443Z" fill="#221F1F" />
                                            <path d="M50.502 49.3679C50.1788 45.3844 46.8331 42.2495 42.7446 42.2495C38.6562 42.2495 35.3037 45.3912 34.9873 49.3885C35.4309 49.4227 35.8607 49.4946 36.2802 49.6041C36.4865 46.2228 39.2958 43.5466 42.7412 43.5466C46.1866 43.5466 48.9959 46.2228 49.2022 49.5972C49.2125 49.7307 49.2228 49.8607 49.2228 50.0011C49.2228 50.3296 49.1885 50.6547 49.1403 50.973C48.7758 53.3857 47.0669 55.357 44.7974 56.1167C44.7459 56.435 44.7149 56.7533 44.7149 57.0887C44.7149 57.2256 44.7252 57.3556 44.7321 57.4891C47.8543 56.6677 50.2063 53.9641 50.4882 50.6753C50.5123 50.4528 50.5226 50.2269 50.5226 50.0011C50.5226 49.7889 50.5123 49.5801 50.4951 49.3748" fill="#221F1F" />
                                            <path d="M57.6648 57.4777C57.4585 60.8521 54.6492 63.5318 51.2038 63.5318C47.7584 63.5318 44.9491 60.8521 44.7393 57.4811C44.7325 57.3476 44.7187 57.2142 44.7187 57.0807C44.7187 56.7453 44.7497 56.427 44.8012 56.1087C45.1657 53.6926 46.8747 51.7213 49.1441 50.965C49.1922 50.6467 49.2266 50.325 49.2266 49.9931C49.2266 49.8562 49.2163 49.7227 49.206 49.5892C46.0873 50.4106 43.7387 53.1108 43.4499 56.4031C43.4293 56.6289 43.4189 56.8514 43.4189 57.0807C43.4189 57.2894 43.4293 57.4982 43.4465 57.707C43.7662 61.6905 47.1154 64.8254 51.2038 64.8254C55.2922 64.8254 58.6448 61.6803 58.9577 57.6899C58.5141 57.6522 58.0843 57.5803 57.6648 57.4743" fill="#00A44F" />
                                            <path d="M2.81956 25.4286H-3.8147e-05V23.3958H8.12179L7.21745 25.4286H5.30219V34.7031H2.81956V25.4286Z" fill="black" />
                                            <path d="M9.24911 34.7031V23.3958H16.1743L15.27 25.4286H11.7145V27.944H14.4757V29.9769H11.7145V32.6737H16.1743L15.27 34.7031H9.24911Z" fill="black" />
                                            <path d="M33.7871 29.4943H33.8525L35.9775 23.3958H39.6911V34.7031H37.322V26.3321H37.2566L34.7293 33.3479H32.6868L30.1904 26.3321H30.0942V34.7031H27.8213V23.3958H31.6312L33.7871 29.4943Z" fill="black" />
                                            <path d="M46.9789 23.3958H44.4894V34.7065H46.9789V23.3958Z" fill="#F68D39" />
                                            <path d="M49.4563 23.3958H46.9668V34.7065H49.4563V23.3958Z" fill="black" />
                                            <path d="M61.0889 23.3958H64.6202C68.3132 23.3958 69.9809 25.1856 69.9809 28.7962C69.9809 31.6812 68.8325 34.6928 64.6546 34.6928H61.0923V23.3958H61.0889ZM63.5509 32.66H64.4586C66.7762 32.66 67.4364 31.1952 67.4364 29.0186C67.4364 26.2945 66.2398 25.4252 64.4414 25.4252H63.5509V32.66Z" fill="black" />
                                            <path d="M73.7484 23.3958H71.2726V34.6928H73.7484V23.3958Z" fill="black" />
                                            <path d="M82.0312 23.3522L85.5007 34.7177H82.8943L81.7595 30.6657H78.7955L77.6127 34.7177H75.1025L78.5548 23.3522H82.0243H82.0312ZM78.854 28.6225H81.6736L81.2335 28.7389C80.7933 27.1646 80.501 26.0763 80.305 25.0702H80.2397C80.0437 26.0455 79.7308 27.2297 79.3251 28.6739L78.854 28.626V28.6225Z" fill="black" />
                                            <path d="M23.7542 23.3513L27.2236 34.7168H24.6172L23.4825 30.6648H20.5185L19.3391 34.7168H16.829L20.2847 23.3513H23.7542ZM20.577 28.6216H23.3966L22.9564 28.738C22.5163 27.1637 22.224 26.0754 22.028 25.0693H21.9627C21.7667 26.0446 21.4572 27.2287 21.048 28.673L20.577 28.625V28.6216Z" fill="black" />
                                            <path d="M56.4144 23.3958V26.9755C56.4144 28.1665 56.4144 29.7305 56.5451 31.3116H56.4797C56.0602 30.1822 55.4447 28.9262 54.8774 27.8448L52.5598 23.3958H51.9512V26.7017C52.3913 27.8516 52.8383 28.8954 53.3713 29.9255L55.8642 34.6963H58.7629V23.3992H56.4144V23.3958Z" fill="black" />
                                            <path d="M51.9477 23.3958H49.4513V34.7065H51.9477V23.3958Z" fill="#50A446" />
                                            <path d="M79.9583 2.82104C73.1775 10.1688 62.8722 9.33371 48.1243 6.56848C40.8828 5.21324 31.2377 3.5363 22.0086 3.9812C21.9467 3.99147 21.8814 4.00516 21.823 4.01543C21.823 4.01543 21.5135 4.01543 20.9736 4.03596C15.8055 4.37819 10.799 5.422 6.46301 7.5986C4.37238 9.30975 1.59404 12.1297 0.00199509 15.9901C0.00199509 15.9901 2.56714 14.498 5.41425 13.4028C5.63776 13.2865 5.85782 13.1701 6.0882 13.0503C19.2097 5.12768 32.1317 7.62597 44.0393 9.42269C55.6994 11.1818 69.8559 14.8573 78.8064 8.59107C79.0058 8.43706 79.2018 8.27279 79.3978 8.10168C79.5388 7.89634 79.6488 7.73207 79.6695 7.691C80.6494 6.00037 81.8873 3.11878 82.0008 0.545197C81.5641 1.05854 80.4844 2.24609 80.0993 2.66361C80.0855 2.6773 80.0786 2.68757 80.0649 2.70126C80.0064 2.76286 79.9686 2.80393 79.9583 2.81419V2.82104Z" fill="white" />
                                            <path d="M79.8508 2.89243C79.8302 2.9027 79.8096 2.91639 79.7855 2.93007C70.3295 9.2819 56.0802 3.46053 44.035 1.38319C31.7801 -0.731807 19.8828 -0.653094 8.81067 5.90065C8.39805 6.15732 7.7069 6.61591 6.87134 7.26957C6.73724 7.37567 6.5997 7.4886 6.45528 7.60154C10.7947 5.42152 15.7978 4.37772 20.9659 4.03891C21.3098 4.01495 21.6536 3.99784 22.0009 3.98073C31.2299 3.53582 40.875 5.21276 48.1166 6.568C62.8679 9.33323 73.1698 10.1683 79.9506 2.82056C79.9265 2.84109 79.8887 2.86505 79.8474 2.89243H79.8508Z" fill="#F68D39" />
                                            <path d="M78.8057 8.59763C69.8552 14.8639 55.6987 11.1883 44.0387 9.42925C32.131 7.63254 19.209 5.13424 6.08752 13.0569C5.94998 13.139 5.81244 13.2246 5.6749 13.3102C8.19534 12.369 11.1834 11.9275 13.8449 11.5477C16.6335 11.1541 19.4497 11.0549 22.2624 11.1712C27.8638 11.4039 33.4411 12.3006 38.9634 13.2041C41.7933 13.6695 44.6094 14.2376 47.4428 14.6825C63.6864 17.2424 71.8736 15.9762 78.4962 9.29921C78.5409 9.25472 78.5856 9.21023 78.6303 9.16574C78.8023 8.9604 79.0877 8.55656 79.318 8.2246C79.1496 8.35122 78.9811 8.47443 78.8091 8.59421L78.8057 8.59763Z" fill="#50A446" />
                                        </svg> */}
                                        <img class="logo2 header-logo" src="images/header-new.png" alt="logo jsw" />

                                    </a>
                                        {/* <img class="logo2" src="https://www.jsw.in/sites/default/files/assets/jsw-logo-footer.png" alt="logo jsw" /> */}
                                        {/* <img class="logo2 header-logo" src="images/jsw.png" alt="logo jsw" /> */}
                                </div>
                            </div>
                            <div id="id7x" class="gjs-grid-column">
                                <a id="i1mew" href="https://www.jsw.in/paris2024" title=""  rel="noreferrer" target="_blank" class="gjs-button">Team India Schedule</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default navigationHook(Header);