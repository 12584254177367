// src/components/Card.js
import React from 'react';
import './css/card.css';

const Card = ({ item, onClick, isSelected }) => {
  return (
    <div
      className={`card-msg ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick(item)}
    >
      {item.message}
      {' '+item.hashtags}
    </div>
  );
};

export default Card;
