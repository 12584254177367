// src/components/banner.js
import React from "react";
import navigationHook from "./hooks";
// import { NavLink } from "react-router-dom";

class Banner extends React.Component {
    logoutHandler = () => {
        localStorage.removeItem("token");
        window.location.reload(false);
    };

    render() {
        return (
            <>
                <section className="ban_sec">
                    <div className="top-banner">
                        <div className="desktop-img">
                            <img src="images/new-desktop-banner.png" alt="Banner" className="banner-image w-100 h-100" />
                        </div>
                        <div className="mobile-img">
                            <img src="images/new-thank.webp" alt="Banner" className="banner-image w-100 h-100" />
                        </div>
                        <div className="banner_card text-white">
                            <h1>Share Your Support For Team <span className="text-orange">In</span>d<span className="text-green">ia</span></h1>
                            <p>#RuknaNahiHai 🧡🤍💚 </p>
                            <button 
                                type="button" 
                                className="btn text-white" 
                                onClick={this.props.openPopup}
                            >
                                Click To Wish Team India
                            </button>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default navigationHook(Banner);
