// src/services/api.js
import axios from 'axios';

export const fetchCardDetails = async () => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/message/all/1?limit=100`); // Replace with your API endpoint
    return response.data;
  } catch (error) {
    console.error('Failed to fetch card details:', error);
    return [];
  }
};

export const fetchWishDetails = async (page = 1, limit = 4, skip = 0) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/wishes/all/${page}?limit=${limit}&skip=${skip}`); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      console.error('Failed to fetch card details:', error);
      return [];
    }
  };

  export const createWishes = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/wish`, data); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      console.error('Failed to create record:', error);
      throw error;
    }
  };

  export const getWishById = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wishes/single/${id}`); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      console.error('Failed to create record:', error);
      throw error;
    }
  };

  export const uploadImage = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, data); // Replace with your API endpoint
      return response.data;
    } catch (error) {
      console.error('Failed to create record:', error);
      throw error;
    }
  };
