import React from "react";
import navigationHook from "./hooks";
import './css/wisheslist.css'
import { fetchWishDetails } from "../services/api";

class WhisesList extends React.Component {
    logoutHandler = () => {
        localStorage.removeItem("token");
        window.location.reload(false);
    };
    constructor(props) {
        super(props);
        this.state = {
            message: 'Hello from Parent!',
            precount: 10000,
            counter:  10000,
            chunk: ['1','0','0','0','0'],
            isChanging: [],
        };
        this.intervalId = null;
    }

    splitIntoChunks = async (num) => {
        const data = await fetchWishDetails(1);

        const number = this.state.counter + num + (data.totalCount | 0);
        const numString = number.toString();
        const regex = /.{1,5}/g;
        console.log(numString);
        const result = numString.split('') || [];
        // console.log(result);
        let changing = [];
        if(this.state.precount> 0 && number!==this.state.precount){
           let difference = this.state.precount.toString().split('');
            difference?.map((item, index)=>{
                if(item!==result[index]){
                    changing.push(index)
                }
            }) 
        }
        console.log('changing', changing);
        this.setState({ isChanging: changing });
        setTimeout(() => {
        this.setState({chunk:result, counter: this.state.counter, isChanging: [], precount: number });
        }, 500); // Duration of the slide-up effect
    };

    async componentDidMount() {
        setInterval(async ()=>{
            this.splitIntoChunks(0)
            console.log("ssss");
        },1000)
    }
      componentDidUpdate(prevProps) {
          
          if (prevProps.totalCount !== this.props.totalCount) {
            // this.setState({counter: this.state.counter+this.props.totalCount})
        }
      }
    componentWillUnmount() {}

    render() {
        console.log(this.props);
        return (
            <>
                <div className="Counter">
                    <img src="images/counter-bg-2.png" className="w-100" alt="" />
                    <div className="counter-container">
                        <div className="counter-title">Wishes Received 🎉</div>
                        <div id="watch">
                        {this.state.chunk.map((chunk, index) => (
                            <ol id={"dial"+ index} key={index}>
                                <li className={ this.state.isChanging.includes(index)? "slide-up":""}>{chunk}</li>
                            </ol>
                        ))}
                            {/* <ol id="dial5">
                                <li>0</li>
                            </ol>
                            <ol id="dial3">
                                <li>1</li>
                            </ol>
                            <ol id="dial2">
                                <li>2</li>
                            </ol>
                            <ol id="dial1">
                                <li>1</li>
                            </ol> */}

                            <div style={{ clear: "both" }}>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="wishes-section py-5">
                    <div class="container">
                        <h2 class="text-center mb-5">Wishes Shared for Indian Team</h2>
                        <div class="row text-start">
                            {this.props?.wishes.map((item, index) => (
                                <div class="col-6 col-md-4 d-flex pb-4" key={index}>
                                    <div class="card" key={item._id}>
                                        <div class="card-body">
                                            <h4>{item.message}</h4>
                                            <p>- {item.name}</p>
                                            <span>{item.location}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div class="load-more text-center pt-5">
                            <button type="button" class="btn" disabled={this.props.page==this.props.totalPage} onClick={(e)=>{ this.props.setPage(this.props.page+1) }}>
                                Load More
                            </button>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default navigationHook(WhisesList);